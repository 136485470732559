import React from "react";

export default function CopyrightPage() {
  return (
    <div id="legalContainer">
      <div id="legal">
      <div>
        <h1>Copyright Dispute Policy</h1>
        <p>
          In accordance with the DMCA, we’ve adopted the policy below toward
          copyright infringement. We reserve the right to (1) block access to or
          remove material that we believe in good faith to be copyrighted
          material that has been illegally copied and distributed by any of our
          advertisers, affiliates, content providers, members or users and (2)
          remove and discontinue service to repeat offenders. Remember that your
          use of Turntable's Services is at all times subject to the{" "}
          <a href="/terms">Terms of Service</a>, which incorporates this
          Copyright Dispute Policy. Any terms we use in this Policy without
          defining them have the definitions given to them in the{" "}
          <a href="/terms">Terms of Service</a>
        </p>

        <p>
          (1) Procedure for Reporting Copyright Infringements. If you believe
          that material or content residing on or accessible through the
          Services infringes your copyright (or the copyright of someone whom
          you are authorized to act on behalf of), please send a notice of
          copyright infringement containing the following information to
          Turntable’s Designated Agent to Receive Notification of Claimed
          Infringement (our “Designated Agent,” whose contact details are listed
          below):
        </p>
        <ul>
          <li>
            (a) A physical or electronic signature of a person authorized to act
            on behalf of the owner of the copyright that has been allegedly
            infringed;
          </li>
          <li>(b) Identification of works or materials being infringed;</li>
          <li>
            (c) Identification of the material that is claimed to be infringing
            including information regarding the location of the infringing
            materials that the copyright owner seeks to have removed, with
            sufficient detail so that Company is capable of finding and
            verifying its existence;
          </li>
          <li>
            (d) Contact information about the notifier including address,
            telephone number and, if available, email address;
          </li>
          <li>
            (e) A statement that the notifier has a good faith belief that the
            material identified in (1)(c) is not authorized by the copyright
            owner, its agent, or the law; and
          </li>
          <li>
            (f) A statement made under penalty of perjury that the information
            provided is accurate and the notifying party is authorized to make
            the complaint on behalf of the copyright owner.
          </li>
        </ul>
        <p></p>
        <p>
          (2) Once Proper Bona Fide Infringement Notification is Received by the
          Designated Agent. Upon receipt of a proper notice of copyright
          infringement, we reserve the right to:
        </p>
        <ul>
          <li>(a) remove or disable access to the infringing material;</li>
          <li>
            (b) notify the content provider who is accused of infringement that
            we have removed or disabled access to the applicable material; and
          </li>
          <li>
            (c) terminate such content provider's access to the Services if he
            or she is a repeat offender.
          </li>
        </ul>
        <p></p>
        <p>
          (3) Procedure to Supply a Counter-Notice to the Designated Agent. If
          the content provider believes that the material that was removed (or
          to which access was disabled) is not infringing, or the content
          provider believes that it has the right to post and use such material
          from the copyright owner, the copyright owner's agent, or, pursuant to
          the law, the content provider may send us a counter-notice containing
          the following information to the Designated Agent:
        </p>
        <ul>
          <li>
            (a) A physical or electronic signature of the content provider;
          </li>
          <li>
            (b) Identification of the material that has been removed or to which
            access has been disabled and the location at which the material
            appeared before it was removed or disabled;
          </li>
          <li>
            (c) A statement that the content provider has a good faith belief
            that the material was removed or disabled as a result of mistake or
            misidentification of the material; and
          </li>
          <li>
            (d) Content provider's name, address, telephone number, and, if
            available, email address, and a statement that such person or entity
            consents to the jurisdiction of the Federal Court for the judicial
            district in which the content provider’s address is located, or, if
            the content provider's address is located outside the United States,
            for any judicial district in which Company is located, and that such
            person or entity will accept service of process from the person who
            provided notification of the alleged infringement.
          </li>
        </ul>
        <p></p>
        <p>
          If a counter-notice is received by the Designated Agent, Company may,
          in its discretion, send a copy of the counter-notice to the original
          complaining party informing that person that Company may replace the
          removed material or cease disabling it in 10 business days. Unless the
          copyright owner files an action seeking a court order against the
          content provider accused of committing infringement, the removed
          material may be replaced or access to it restored in 10 to 14 business
          days or more after receipt of the counter-notice, at Company's
          discretion.
        </p>
        <p>
          In the event the alleged copyright infringement concerns content made
          available through third party APIs (e.g. YouTube or SoundCloud),
          please be advised that the Company does not have control over such
          content. In such cases, we will advise you to direct the Notification
          of Claimed Infringement to the applicable third party service, which
          can be located by hovering over the top left corner of the content.
          <br />
          Please contact Company's Designated Agent at the following address:
          <br />
          Copyright Agent
          <br />
          c/o Turntable.fm
          <br />
          P.O. Box 454
          <br />
          Brooklyn, NY 11211
          <br />
          <br />
          Our Copyright Agent can also be reached via email at{" "}
          <a href="mailto:copyright@testco.de">copyright@testco.de</a>.
          Please include “copyright violation” in the subject.
        </p>
      </div>
      </div>
    </div>
  );
}
