import Lottie from 'react-lottie';
import * as animationData from './backgroundAnimation.json';
export default function BackgroundLottie() {
  const options = {
    loop: true,
    autoplay: true,
    animationData,
  }
  return (
    <div className="lottieBackground">
      <Lottie options={options} />
    </div>
  )
}
